import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import Box from '@mui/material/Box';
import { ProcessingBuildListItem, ToastType, Zone, ZoneListItem } from '../interfaces';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useAppSelector } from '../store/hooks';
import { selectProcessingBuilds, selectZones } from '../store/maps.reducer';
import { MapPolygonPreviewer } from './MapPolygonPreviewer';
import { createProcessingBuild } from '../api/createProcessingBuild';
import { useToastMessage } from '../hooks/useToastMessage';
import ToastWrapper from './ToastWrapper';
import { Formik, Form, Field } from 'formik';
import { object, string, addMethod } from 'yup';
import { getZoneById } from '../api/getZoneById';
import { formatDate } from '../utils/formatDate';

declare module 'yup' {
    interface StringSchema {
        uniqueName(builds: ProcessingBuildListItem[], message: string): this;
    }
}

// Extend Yup for unique name validation
addMethod(string, 'uniqueName', function (processingBuilds: ProcessingBuildListItem[], message) {
    return this.test('uniqueName', message, function (value) {
        const { path, createError } = this;
        const isUnique = processingBuilds.every((build) => build.name.toLowerCase() !== value?.toLowerCase());
        return isUnique || createError({ path, message });
    });
});

interface Props {
    open: boolean;
    onClose: () => void;
    onProcessingBuildCreated: () => void;
}

export const AddProcessingBuildDialog = ({ open, onClose, onProcessingBuildCreated }: Props) => {
    const [selectedZone, setSelectedZone] = useState<ZoneListItem | null>(null);
    const [selectedZoneFull, setSelectedZoneFull] = useState<Zone | null>(null);
    const zones = useAppSelector(selectZones);
    const processingBuilds = useAppSelector(selectProcessingBuilds);
    const [loading, setLoading] = useState<boolean>(false);
    const { isOpened, toastMessage, setToastMessage, handleCloseToast } = useToastMessage();

    const [loadingMap, setLoadingMap] = useState(false);

    useEffect(() => {
        (async () => {
            if (selectedZone) {
                setLoadingMap(true);
                try {
                    const zone = await getZoneById(selectedZone._id);
                    setSelectedZoneFull(zone);
                } catch (error) {
                    console.error('Failed to fetch entity:', error);
                } finally {
                    setLoadingMap(false);
                }
            } else {
                setSelectedZoneFull(null);
            }
        })();
    }, [selectedZone]);

    const validationSchema = object({
        name: string()
            .required('Назва білда є обов’язковою')
            .uniqueName(processingBuilds!, 'Назва білда повинна бути унікальною'),
        zoom: string().required('Зум є обов’язковим'),
    });

    const triggerCreateProcessingBuild = async (values: { name: string; zoom: string }) => {
        try {
            setLoading(true);
            await createProcessingBuild({
                name: values.name,
                zoneId: selectedZone!._id,
                zoom: parseInt(values.zoom),
            });
            onProcessingBuildCreated();
        } catch (e) {
            console.error('Create processing build failed', e);
            setToastMessage({
                message: `При створенні білда відбулася помилка`,
                type: ToastType.WARNING,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog fullScreen onClose={onClose} open={open}>
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'right',
                }}
            >
                <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        name: '',
                        zoom: '15',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={triggerCreateProcessingBuild}
                >
                    {({ touched, errors, isValid, setFieldValue }) => (
                        <Form>
                            <Box sx={{ display: 'flex' }}>
                                <div className="flex-grow relative w-[250px] pt-2">
                                    <Box>
                                        {zones && (
                                            <Autocomplete
                                                onChange={(e, newValue) => {
                                                    if (!newValue) {
                                                        setSelectedZone(null);
                                                        setFieldValue('name', '');
                                                    } else {
                                                        const zone = zones.find((z) => z._id === newValue.id);
                                                        setSelectedZone(zone!);
                                                        setFieldValue(
                                                            'name',
                                                            `${zone!.name}-${formatDate(
                                                                new Date().toISOString(),
                                                            )}`,
                                                        );
                                                    }
                                                }}
                                                fullWidth
                                                size={'small'}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Зона" />
                                                )}
                                                isOptionEqualToValue={(newValue, oldValue) =>
                                                    newValue.id === oldValue.id
                                                }
                                                options={zones.map((zone) => ({
                                                    label: zone.name,
                                                    id: zone._id,
                                                }))}
                                            />
                                        )}
                                    </Box>

                                    <Box sx={{ marginTop: 2 }}>
                                        <Field
                                            as={TextField}
                                            name="name"
                                            label="Назва нового білда"
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            error={touched.name && !!errors.name}
                                            helperText={touched.name && errors.name}
                                        />
                                    </Box>

                                    <Box sx={{ marginTop: 2 }}>
                                        <Field
                                            as={TextField}
                                            name="zoom"
                                            label="Зум"
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            disabled
                                            error={touched.zoom && !!errors.zoom}
                                            helperText={touched.zoom && errors.zoom}
                                        />
                                    </Box>

                                    <Box sx={{ marginTop: 2 }}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="outlined"
                                            disabled={!isValid || !selectedZone || loading}
                                        >
                                            Запустити білд
                                            {loading && (
                                                <span className="ml-2 inline-flex">
                                                    <CircularProgress size={15} />
                                                </span>
                                            )}
                                        </Button>
                                    </Box>
                                </div>
                                <Box
                                    sx={{
                                        marginLeft: 3,
                                        height: 'calc(100vh - 80px)',
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {loadingMap ? (
                                        <CircularProgress />
                                    ) : selectedZoneFull ? (
                                        <MapPolygonPreviewer
                                            key={selectedZoneFull._id}
                                            geoJson={selectedZoneFull.geoJson}
                                        />
                                    ) : (
                                        <Box>No data available</Box>
                                    )}
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
            <ToastWrapper
                open={isOpened}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message={toastMessage?.message}
                type={toastMessage?.type}
            />
        </Dialog>
    );
};
