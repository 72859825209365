import { JSX } from 'react';
import { MultiPolygon, Polygon } from 'geojson';

export interface LatLng {
    lat: number;
    lng: number;
}

export interface Tile {
    x: number;
    y: number;
    z: number;
    key: string;
    source: string;
}

export enum ToastType {
    WARNING = 'WARNING',
    SUCCESS = 'SUCCESS',
}

export interface IToast {
    message: string | JSX.Element;
    type: ToastType;
}

export enum ProcessingBuildStatus {
    CREATED = 'CREATED',
    PROCESSING = 'PROCESSING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    DEPRECATED = 'DEPRECATED',
    UPGRADING = 'UPGRADING',
}

export enum MapUploadStatus {
    CLIENT_UPLOAD = 'CLIENT_UPLOAD',
    PROCESSING = 'PROCESSING',
    COMPLETED = 'COMPLETED',
}

export interface MapEntity {
    _id: string;
    name: string;
    createdBy: string;
}

export interface Geometry {
    geoJson: Polygon | MultiPolygon;
}

export interface MapEntityWithGeometry extends MapEntity, Geometry {}

export interface ProcessingBuildRequestProps {
    zoneId: string;
    name: string;
    zoom: number;
}

export interface ZoneListItem extends MapEntity {
    createdAt: string;
    area: number;
}

export interface Zone extends ZoneListItem, MapEntityWithGeometry {}

export enum VersionValidity {
    NOT_VALID = 0,
    VALID = 1,
}

export interface ProcessingBuildListItem extends MapEntity {
    status: ProcessingBuildStatus;
    processedMap?: ProcessedMapListItem;
    createdAt: string;
    updatedAt?: string;
    finishedAt?: string;
    models: any;
    error?: string;
    zoom: number;
}

export interface ProcessingBuild extends Omit<ProcessingBuildListItem, 'status'>, MapEntityWithGeometry {}

export interface ProcessedMapListItem extends MapEntity {
    createdAt: string;
    buildId: string;
    zoneId: string;
    models: string[];
    zoom: number;
    uploadKey: string;
}

export interface ProcessedMap extends ProcessedMapListItem, MapEntityWithGeometry {}

export interface MapUploadListItem extends Geometry {
    _id: string;
    name?: string;
    upload_id: number;
    zoom: number;
    directory: string;
    status: MapUploadStatus;
    createdBy: string;
    createdAt: string;
}

export interface ObjectId {
    $oid: string;
}

export interface Device {
    deviceId: string;
    baseImageS3Link?: string;
    status?: string;
    commitHash?: string;
    deviceSerial?: number;
    zymkeySerial?: string;
    rpiSerial?: string;
    username?: string;
    hostname?: string;
    sshPort?: number;
    accessPointSSID?: string;
    vpnProfile?: string;
    vpnIPAddress?: string;
    vpnPubkey?: string;
    bundleDevicePubkey?: string;
    bundleCloudPubkey?: string;
    bundleCloudPrivkey?: string;
    mapS3Link?: string;
    secureBootPrivkey?: string;
    zymkeyPubkey?: string;
    deviceSSHPubkey?: string;
    deviceSSHPubkeyType?: string;
    deviceSSHPubkeyHash?: string;
    systemVersion?: string;
    createdAt?: string;
    updatedAt?: string;
    organization?: string;
    organizationDetails?: Organization;
}

export interface DeviceSecrets {
    userPassword: string;
    accessPointPassword: string;
}

export interface OrganizationForm {
    id?: string | null;
    name?: string;
    labelColor?: string;
    oscarConfiguration?: SystemConfiguration | null;
}

export interface UpsertOrganizationDialogProps {
    open: boolean;
    onClose: () => void;
    initialValues: OrganizationForm;
}

export interface Organization {
    id: string | null;
    name: string;
    labelColor: string;
    oscarConfigurationId: string | null;
}

export interface DeviceWithSticks extends Device {
    activationSticks: ActivationStick[];
}

export interface ActivationStick {
    activationStickId: string;
    deviceId: string;
    deviceSerial: number;
    partitionUUID: string;
    rpiSerial: string;
    bundleS3Link: string;
    systemVersion: string;
}

export interface SystemConfiguration {
    _id: ObjectId;
    systemVersion: string;
    configuration: {
        applicationVersion: string;
        mapVersion: string;
        containerVersion: string;
    };
}

export enum UserRoles {
    ADMINS = 'admins',
    FARM = 'farm',
}

export interface TileSourcesResult {
    presigned_url: string;
    geoJson: Polygon | MultiPolygon | Array<Polygon | MultiPolygon>;
}

export interface ShapeHistoryItem {
    name: string;
    geoJson: Polygon | MultiPolygon;
}
