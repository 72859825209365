import axios from 'axios';
import config from '../config';
import { Organization } from '../interfaces';

export const upsertOrganization = (organization: Organization) => {
    return axios
        .post(`${config.API_URL}/organizations/${organization.id}`, organization)
        .then((response: any) => {
            return response.data.data;
        });
};
