import React, { useMemo, useState } from 'react';

import {
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    getGridDateOperators,
    getGridStringOperators,
    GridRowParams,
    GridSlots,
    useGridApiContext,
    getGridNumericOperators,
} from '@mui/x-data-grid';
import { transformKeyToTitle } from '../utils/transformKeyToTitle';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import { formatDate } from '../utils/formatDate';

import { useAppDispatch, useAppSelector } from '../store/hooks';
import { loadProcessingBuilds, selectProcessingBuilds } from '../store/maps.reducer';
import { CopyTextCell } from './GridCells/CopyTextCell';
import { ProcessingBuildListItem, ProcessingBuildStatus } from '../interfaces';
import { SelectedPolygonDialog } from './SelectedPolygonDialog';
import AddIcon from '@mui/icons-material/Add';
import { ProcessingStatusCell } from './GridCells/ProcessingStatusCell';
import { ProcessingBuildModelsCell } from './GridCells/ProcessingBuildModelsCell';
import { AddProcessingBuildDialog } from './AddProcessingBuildDialog';
import { useManageGrid } from '../hooks/useManageGrid';
import { getProcessingBuild } from '../api/getProcessingBuild';
import { ProcessedBuildActionsCell } from './GridCells/ProcessedBuildActionsCell';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { navigateToTilesViewer } from './SourceTilesViewer/navigateToTilesViewer';

function Toolbar() {
    const processingBuilds = useAppSelector(selectProcessingBuilds);
    const gridApi = useGridApiContext();

    const selectedRows = Array.from(gridApi.current.getSelectedRows().values());

    const dispatch = useAppDispatch();
    const [addProcessingDialogOpened, setAddProcessingDialogOpened] = useState<boolean>(false);

    const onReload = () => {
        dispatch(loadProcessingBuilds());
    };
    const onAdd = () => {
        setAddProcessingDialogOpened(true);
    };

    const isBuildInProgress = useMemo(() => {
        if (processingBuilds) {
            return !!processingBuilds.find((b) => b.status === ProcessingBuildStatus.PROCESSING);
        }
    }, [processingBuilds]);

    const onCheckMultipleSources = () => {
        navigateToTilesViewer({
            processedMapNames: selectedRows
                .filter((row) => !!row.processedMap)
                .map((row) => row.processedMap!.name),
        });
    };

    return (
        <>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <Button startIcon={<RefreshIcon />} color="primary" onClick={onReload}>
                    Reload
                </Button>
                <Button disabled={isBuildInProgress} startIcon={<AddIcon />} color="primary" onClick={onAdd}>
                    Add
                </Button>

                <Button
                    disabled={selectedRows.length < 2}
                    startIcon={<ZoomInMapIcon />}
                    color="primary"
                    onClick={onCheckMultipleSources}
                >
                    Check Combined Sources
                </Button>
            </GridToolbarContainer>

            {addProcessingDialogOpened && (
                <AddProcessingBuildDialog
                    open={addProcessingDialogOpened}
                    onProcessingBuildCreated={() => {
                        setAddProcessingDialogOpened(false);
                        onReload();
                    }}
                    onClose={() => {
                        setAddProcessingDialogOpened(false);
                    }}
                />
            )}
        </>
    );
}

export const ProcessingBuildsTableGrid = () => {
    const queryParams = new URLSearchParams(location.search);
    const zoneIdParam = queryParams.get('zoneId');

    const {
        apiRef,
        getViewRows,
        sortModel,
        columnsConfig,
        columnsWidth,
        gridFilterModel,
        handleColumnVisibilityChange,
        handleColumnWidthChange,
        handleSortModelChange,
        handleFilterModelChange,
        onCellKeyDown,
        gridStateSynced,
    } = useManageGrid('map/builds', {
        filterModel: zoneIdParam
            ? {
                  items: [
                      {
                          field: 'zoneId',
                          operator: 'equals',
                          value: zoneIdParam,
                      },
                  ],
              }
            : undefined,
    });
    const processingBuilds = useAppSelector(selectProcessingBuilds);
    const [selectedRow, setSelectedRow] = useState<ProcessingBuildListItem | null>(null);

    const columns: GridColDef[] = useMemo(() => {
        return [
            { key: 'id', renderCell: CopyTextCell },
            { key: 'zoneId', renderCell: CopyTextCell },
            { key: 'name', renderCell: CopyTextCell },
            { key: 'status', renderCell: ProcessingStatusCell, width: 160 },
            {
                key: 'executionTime',
                filterOperators: getGridNumericOperators,
                valueFormatter: (diffInMilliseconds: number) => {
                    if (!diffInMilliseconds) {
                        return;
                    }
                    const minutes = Math.floor(diffInMilliseconds / 60000); // Convert milliseconds to minutes
                    const seconds = Math.floor((diffInMilliseconds % 60000) / 1000); // Get remaining seconds
                    return `${minutes}:${seconds.toString().padStart(2, '0')} хв`; // Format as min:sec with leading zero for seconds
                },
                width: 160,
            },
            { key: 'createdBy', renderCell: CopyTextCell },
            { key: 'actions', renderCell: ProcessedBuildActionsCell, width: 150 },
            { key: 'uploadKey', renderCell: CopyTextCell, width: 450 },
            {
                key: 'createdAt',
                valueFormatter: formatDate,
                filterOperators: getGridDateOperators,
            },
            {
                key: 'finishedAt',
                valueFormatter: formatDate,
                filterOperators: getGridDateOperators,
            },
            { key: 'models', renderCell: ProcessingBuildModelsCell, width: 350 },
            { key: 'zoom', renderCell: CopyTextCell, width: 100 },
        ].map((col) => {
            return {
                field: col.key,
                headerName: transformKeyToTitle(col.key),
                renderCell: col.renderCell,
                valueFormatter: col.valueFormatter,
                width: columnsWidth[col.key] || col.width || 250,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                filterOperators: col.filterOperators ? col.filterOperators() : getGridStringOperators(),
            };
        });
    }, [columnsWidth]);

    const rows = processingBuilds?.map((build) => {
        const row: any = {
            ...build,
            id: build._id,
        };

        row.models = Object.keys(build.models);

        if (row.createdAt) row.createdAt = new Date(row.createdAt);

        if (build.finishedAt) {
            row.executionTime = new Date(build.finishedAt).getTime() - new Date(build.createdAt).getTime();
        }

        if (build.processedMap) {
            row.uploadKey = build.processedMap.uploadKey;
        }

        return row;
    });

    const onRowDoubleClick = (params: GridRowParams) => {
        setSelectedRow(params.row as ProcessingBuildListItem);
    };

    const viewRows = useMemo(() => {
        const rows = getViewRows();
        if (rows) {
            return rows as ProcessingBuildListItem[];
        }

        return processingBuilds;
    }, [selectedRow, processingBuilds]);

    return (
        <>
            {gridStateSynced && (
                <>
                    <DataGrid
                        apiRef={apiRef}
                        rows={rows}
                        columns={columns}
                        slots={{
                            toolbar: Toolbar as GridSlots['toolbar'],
                        }}
                        checkboxSelection
                        disableRowSelectionOnClick
                        onRowDoubleClick={onRowDoubleClick}
                        sortModel={sortModel}
                        onCellKeyDown={onCellKeyDown}
                        filterModel={gridFilterModel}
                        onSortModelChange={handleSortModelChange}
                        onColumnWidthChange={handleColumnWidthChange}
                        onColumnVisibilityModelChange={handleColumnVisibilityChange}
                        onFilterModelChange={handleFilterModelChange}
                        columnVisibilityModel={columnsConfig}
                    />

                    {selectedRow && viewRows && (
                        <SelectedPolygonDialog
                            open={!!selectedRow}
                            list={viewRows}
                            getEntity={getProcessingBuild}
                            selectedRow={selectedRow}
                            onSelectedRowChange={(row) => {
                                setSelectedRow(row as ProcessingBuildListItem);
                            }}
                            onClose={() => {
                                setSelectedRow(null);
                            }}
                        />
                    )}
                </>
            )}
        </>
    );
};
